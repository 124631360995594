exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-success-guide-tsx": () => import("./../../../src/pages/app-success-guide.tsx" /* webpackChunkName: "component---src-pages-app-success-guide-tsx" */),
  "component---src-pages-augmented-reality-spatial-computing-tsx": () => import("./../../../src/pages/augmented-reality-spatial-computing.tsx" /* webpackChunkName: "component---src-pages-augmented-reality-spatial-computing-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-business-cards-augmented-reality-ar-vr-xr-tsx": () => import("./../../../src/pages/business-cards-augmented-reality-ar-vr-xr.tsx" /* webpackChunkName: "component---src-pages-business-cards-augmented-reality-ar-vr-xr-tsx" */),
  "component---src-pages-careers-marketing-tsx": () => import("./../../../src/pages/careers-marketing.tsx" /* webpackChunkName: "component---src-pages-careers-marketing-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-convertion-landing-book-meeting-tsx": () => import("./../../../src/pages/convertion-landing/book-meeting.tsx" /* webpackChunkName: "component---src-pages-convertion-landing-book-meeting-tsx" */),
  "component---src-pages-convertion-landing-hero-image-tsx": () => import("./../../../src/pages/convertion-landing/hero_image.tsx" /* webpackChunkName: "component---src-pages-convertion-landing-hero-image-tsx" */),
  "component---src-pages-convertion-landing-hero-tsx": () => import("./../../../src/pages/convertion-landing/hero.tsx" /* webpackChunkName: "component---src-pages-convertion-landing-hero-tsx" */),
  "component---src-pages-convertion-landing-learn-more-video-tsx": () => import("./../../../src/pages/convertion-landing/learn-more-video.tsx" /* webpackChunkName: "component---src-pages-convertion-landing-learn-more-video-tsx" */),
  "component---src-pages-convertion-landing-new-hero-tsx": () => import("./../../../src/pages/convertion-landing/new-hero.tsx" /* webpackChunkName: "component---src-pages-convertion-landing-new-hero-tsx" */),
  "component---src-pages-convertion-landing-workflow-tsx": () => import("./../../../src/pages/convertion-landing/workflow.tsx" /* webpackChunkName: "component---src-pages-convertion-landing-workflow-tsx" */),
  "component---src-pages-e-commerce-augmented-reality-ar-vr-xr-tsx": () => import("./../../../src/pages/e-commerce-augmented-reality-ar-vr-xr.tsx" /* webpackChunkName: "component---src-pages-e-commerce-augmented-reality-ar-vr-xr-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-landing-clients-logos-tsx": () => import("./../../../src/pages/landing/clients-logos.tsx" /* webpackChunkName: "component---src-pages-landing-clients-logos-tsx" */),
  "component---src-pages-landing-clients-tsx": () => import("./../../../src/pages/landing/clients.tsx" /* webpackChunkName: "component---src-pages-landing-clients-tsx" */),
  "component---src-pages-landing-contact-us-tsx": () => import("./../../../src/pages/landing/contact-us.tsx" /* webpackChunkName: "component---src-pages-landing-contact-us-tsx" */),
  "component---src-pages-landing-demos-tsx": () => import("./../../../src/pages/landing/demos.tsx" /* webpackChunkName: "component---src-pages-landing-demos-tsx" */),
  "component---src-pages-landing-footer-tsx": () => import("./../../../src/pages/landing/footer.tsx" /* webpackChunkName: "component---src-pages-landing-footer-tsx" */),
  "component---src-pages-landing-hero-tsx": () => import("./../../../src/pages/landing/hero.tsx" /* webpackChunkName: "component---src-pages-landing-hero-tsx" */),
  "component---src-pages-landing-info-tsx": () => import("./../../../src/pages/landing/Info.tsx" /* webpackChunkName: "component---src-pages-landing-info-tsx" */),
  "component---src-pages-landing-navbar-tsx": () => import("./../../../src/pages/landing/navbar.tsx" /* webpackChunkName: "component---src-pages-landing-navbar-tsx" */),
  "component---src-pages-landing-portfolio-tsx": () => import("./../../../src/pages/landing/portfolio.tsx" /* webpackChunkName: "component---src-pages-landing-portfolio-tsx" */),
  "component---src-pages-landing-services-tsx": () => import("./../../../src/pages/landing/services.tsx" /* webpackChunkName: "component---src-pages-landing-services-tsx" */),
  "component---src-pages-mvp-guide-startups-tsx": () => import("./../../../src/pages/mvp-guide-startups.tsx" /* webpackChunkName: "component---src-pages-mvp-guide-startups-tsx" */),
  "component---src-pages-outsourcing-tsx": () => import("./../../../src/pages/outsourcing.tsx" /* webpackChunkName: "component---src-pages-outsourcing-tsx" */),
  "component---src-pages-partners-tsx": () => import("./../../../src/pages/partners.tsx" /* webpackChunkName: "component---src-pages-partners-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-shopify-developers-tsx": () => import("./../../../src/pages/shopify-developers.tsx" /* webpackChunkName: "component---src-pages-shopify-developers-tsx" */),
  "component---src-pages-software-app-development-tsx": () => import("./../../../src/pages/software-app-development.tsx" /* webpackChunkName: "component---src-pages-software-app-development-tsx" */),
  "component---src-pages-terms-of-service-tsx": () => import("./../../../src/pages/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-tsx" */),
  "component---src-pages-thank-you-guide-tsx": () => import("./../../../src/pages/thank-you-guide.tsx" /* webpackChunkName: "component---src-pages-thank-you-guide-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-pages-trabaja-con-nosotros-tsx": () => import("./../../../src/pages/trabaja-con-nosotros.tsx" /* webpackChunkName: "component---src-pages-trabaja-con-nosotros-tsx" */),
  "component---src-pages-xr-e-commerce-demo-tsx": () => import("./../../../src/pages/xr/e-commerce-demo.tsx" /* webpackChunkName: "component---src-pages-xr-e-commerce-demo-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/BlogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

