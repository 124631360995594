import get from 'lodash/get';
export const t = (key) => {
  return get(translations, key);
}

export const translations = {
  en: {
    "careers-mkt": {
      "work-with-us": {
        "title": "We are looking for you!",
        "description-1": "Join a creative and innovative team, committed to the success of Startups.",
        "description-2": "We develop software products for Startups using modern technologies and lots of creativity, demanding tech companies, working here is truly a challenge."
      },
      "about-us": {
        "title": "We are Software on the Road",
        "description": "A development agency specialized in creating innovative technological solutions for Startups across America and Europe."
      },
      "selection-process": {
        "title": "Selection Process",
        "step-1": "Leave your information and CV in the form (at the end of the page).",
        "step-2": "We will analyze your profile, and invite you to an initial interview to get to know you.",
        "step-3": "We'll meet and talk about your posible integration to our company."
      },
      "requirements": {
        "title": "Requirements",
        "list-1": "Required: Education in marketing, design, communication, advertising, or related fields.",
        "list-2": "Required: Proven experience in digital marketing.",
        "list-3": "Experience in marketing for digital services",
        "list-4": "Social media management, interaction and content creation: Meta, LinkedIn, YouTube",
        "list-5": "Copywriting, writing, SEO",
        "list-6": "Email Marketing",
        "list-7": "Ads on Meta"
      },
      "benefits": {
        "title": "Benefits",
        "list-1": "Salary in USDT.",
        "list-2": "100% remote work from Argentina.",
        "list-3": "Flexible hours and autonomy.",
        "list-4": "Coverage for English classes, Internet, and prepaid medicine.",
        "list-5": "End-of-year and mid-year bonuses.",
        "list-6": "10 days of vacation during the first year, increases with seniority.",
      },
      "apply-here-button": "APPLY HERE!"
    },
    "careers": {
      "work-with-us": {
        "title": "We're Hiring!",
        "description-1": "Join a team committed to Software Engineering.",
        "description-2": "We develop highly scalable products for demanding technology companies, working here is truly a challenge."
      },
      "about-us": {
        "title": "We Are Software on the Road",
        "description": "An agency specialized in creating innovative technological solutions, always working with cutting-edge technologies. For over 6 years, we have been offering our ingenuity and wisdom to the most innovative companies in America."
      },
      "selection-process": {
        "title": "Selection Process",
        "step-1": "Leave us your details and CV in the form (at the bottom of the page).",
        "step-2": "We will analyze your profile and invite you to an initial interview to get to know you.",
        "step-3": "Brief live code exercise and a technical interview for you to demonstrate your knowledge."
      },
      "requirements": {
        "title": "Requirements",
        "list-1": "Solid experience in Node.js or React.js, including real project implementation.",
        "list-2": "Knowledge of design patterns and software engineering concepts (SOLID, KISS, DRY, etc).",
        "list-3": "Data structures, algorithms, complexity times.",
        "list-4": "Competencies in Javascript/Typescript, including skills with arrays, maps, array functions, promises, and asynchronous code.",
        "list-5": "Minimum B2 level of English, with the ability to achieve a conversational level of C1 as we work directly with English-speaking clients and hold weekly meetings with them. Oral communication is essential."
      },
      "benefits": {
        "title": "Benefits",
        "list-1": "Salary in USDT.",
        "list-2": "100% remote work from Argentina.",
        "list-3": "Flexible schedule and autonomy.",
        "list-4": "Growth opportunities, career plan, and projects will drive your career.",
        "list-5": "Coverage of English, Internet, and prepaid medicine.",
        "list-6": "End and mid-year bonuses.",
        "list-7": "10 days of vacation during the first year, increases with seniority."
      },
      "apply-here-button": "APPLY HERE!"
    },
    cookie_consent: 'This website uses cookies to improve your experience. By using our website and our products, you agree to our use of cookies.',
    cookie_consent_accept: 'Accept',
    index_page_title: "Software On The Road - Development Agency",
    post_date: "Written on {postedOn, date, long}",
    index: {
      title: "SOTR - Software development agency for startups ",
      description: "A parter you can trust! We have been helping startups for the last 10 years, we can guide you through the whole software development lifecycle, from the MVP conception until product adquisition",
    },
    not_found: {
      back_link: 'Go to the front page →',
    },
    "landing-app-dev": {
      "cal-meeting": "santypk4/free-consultation",
      "title": "Custom software and app development - Software On The Road ",
      "description": "We build software and custom apps for your startup company. We create any kind of application, software or website you can imagine, with cutting edge technologies such as A.I., Blockchain solutions and native mobile app development.",
      "meeting-link": 'https://cal.com/santypk4/free-consultation',
      "hero": {
        "title": {
          "prefix": "We build ",
          "highlight": "the app",
          "sufix": " you are looking for!"
        },
        "description": "With over +10 apps in the market, we've mastered the software development process to guide your software project to a successful result.",
        "callToAction": "Book free consultation call"
      },
      "contact": {
        "title": "Contact us and tell us about your app idea ",
        "description": "We will get in touch with you as soon as possible to provide you with a suitable quote and turn your product into reality."
      },
      "new-contact": {
        "title": "Let's chat!",
        "description": "Share your contact and we'll get the conversation started."
      },
      "learn-video-more": {
        "title": "Conoce más sobre nuestro trabajo",
        "description": "Trabajamos codo a codo con nuestros clientes para realizar y entregar una aplicación funcional en el menor tiempo posible. En el siguiente vídeo te mostramos cómo es nuestro proceso y cómo trabajamos:"
      },
      "workflow": {
        "title": "Our process",
        "description": "The process of creating an application is iterative, involving continuous improvement, taking into account feedback from the founder and the market."
      },
      "client-logos" : {
        "title": "Happy customers"
      },
      "book-meeting": {
        "title": "Book a call with us!",
        "description": "You can schedule a video call meeting with us here to tell us about your app idea. Choose the day and time that suits you best!"
      }
    },
    "blog": {
      "title1": "Welcome to SOTR’s",
      "title2": "blog",
      "description": "Whether you're a seasoned professional or just starting your coding journey, this blog is here to provide you with valuable insights, practical tips, and inspiring stories from the software development world.",
      "subscribe-title": "Subscribe to our newsletter and get notified when a new blog post is uploaded!",
      "subscribe-label": "Email adress",
      "subscribe-button": "Submit",
      "title-cards": "Blog Entries",
    },
    "navbar": {
      "services": "Services",
      "projects": "Projects",
      "clients": "Reviews",
      "blog": "Blog",
      "contact": "Schedule a meeting"
    },
    "hero": {
      "title": {
        "prefix": "Cutting-edge ",
        "highlight": "Software Development",
        "sufix": ""
      },
      "description": "We are your technology partner! Our objective is to guide you in your entrepreneurial journey, whether you need help developing an MVP for your startup, or scaling your existing piece of software to thousands of users.",
      "button": "Book a free consultation call!"
    },
    "thank-you": {
      "title": "Thank you",
      "description": "We'll reach out as soon as possible. Stay Tuned!"
    },
    "thank-you-guide": {
      "title": "Thank you",
      "description": "We've sent the guide to your email."
    },
    "services": {
      "title": "Services",
      "for-startups": {
        "title": "For Start-ups",
        "description": "We have mastered the software development lifecycle successful startups need. We build balanced MVPs planned for scalability and reliability, so you can iterate quickly without compromising quality and future scalability.",
        "firstItem": {
          "title": "MVPs development for Startups",
          "description": "We help you simplify your software product, emphasizing the core features that stand your product apart from the competition to reduce scope and make iterations quicker so you can launch your product as quickly as possible."
        },
        "secondItem": {
          "title": "SaaS software development",
          "description": "We develop Software as a Service products that scale with cash-flow positive returns. We build your SaaS to provide value to your customers from day one."
        },
        "thirdItem": {
          "title": "A CTO as a service model",
          "description": "We know how hard it is to find a Chief Technology Officer for your team, while you are at it, we are the technology partner you can trust to make the right technology decisions."
        }
      },
      "staff-augmentation": {
        "title": "I.T. Staff augmentation & Outsourcing",
        "description": "We are the developers you've looking for!",
        "development-outsourcing": "Development Outsourcing: we assemble the right team for your needs! - Developers, UX/UI designers, and a project manager will lead your project to success, so you don't have to handle any development process and focus on growing your business instead. ",
        "it-augmentation": "I.T. Staff Augmentation:  engineers will dive into your company's culture and blend into your existing development team so you can accelerate your development process without compromising quality.",
        "firstItem": {
          "title": "I.T. Staff Augmentation",
          "description": "We have a selection of prevet I.T. profiles highly trained in agile software development, we take care of social security, wages, and provision of the hardware and software required to work."
        },
        "secondItem": {
          "title": "Development Outsourcing",
          "description": "Recruiting a development team with cutting-edge technologies can take months, but we have one ready to work for you."
        },
        "thirdItem": {
          "title": "Consulting",
          "description": "You often don't need a whole team, nor another I.T. professional, but rather an expert who can unblock your team's road. We have two software experts with more than 15 years of combined experience, Santiago Quinteros and Guido Rial, who can train your developers to build scalable software, or work on high-complexity tasks."
        }
      }
    },
    "portfolio": {
      "title": "Our projects",
      "visit": "Visit project",
      "resuite": {
        "description": "Streamline your Real Estate market research with an easy-to-use, commitment-free platform that delivers all the insight you need to conduct an in-depth analysis and due diligence of your next listing or investment property at a fraction of the cost."
      },
      "capablemaker": {
        "description": "E-Learning platform to assist trades teachers and their students to better facilitate just-in-time instruction and differentiated instruction and assist trades educators in content development and distribution."
      },
      "paygrade": {
        "description": "Financial literacy platform designed to teach students real-world finance skills through interactive modules and practical simulations. This educational tool offers personalized learning paths, real-time market data, and expert insights to empower students with essential financial knowledge and decision-making skills."
      },
      "snapsales": {
        "description": "Quickly create visually appealing landing pages for your products with easy steps and sell them for crypto coins. This user-friendly platform streamlines the process of building and customizing landing pages, integrating seamless cryptocurrency payment options for a global reach."
      },
      "drinkko": {
        "description": "Drinkko is a meeting point between people who want to drink good beer at great prices and bars that need to attract clients. Through Drinkko, bars can attract customers by offering discounted beers at specific times of the day. At the same time, users receive notifications of discounted beer prices in bars around them."
      },
      "cryptocampo": {
        "description": "Tokenization of real-world assets through a cutting-edge platform that transforms physical assets into digital tokens. This innovative solution enables fractional ownership, enhanced liquidity, and global accessibility by leveraging blockchain technology."
      }
    },
    "clients": {
      "title": "Testimonials",
      "capablemaker": {
        "dialog": "Software on the road was one of the best enterprises I had ever worked with.",
        "body": "Software on the road's work led to successfully onboarding new users. The team managed their tasks effectively, worked efficiently, and went the extra mile to ensure the project's success. They were impressed with their ability to follow instructions well and deliver exactly what the client needed."
      },
      "ktblack": {
        "dialog": "Their communication and professionalism are impressive.",
        "body": "Software on the road has demonstrated successful project progress using the client's internal project management tool, demonstrating adaptability. The team has delivered items on time and has been very responsive to the client's needs. Moreover, their communication and professionalism are excellent."
      },
      "paygrade": {
        "dialog": "Software on the Road was not just an extraordinary development team; they became trusted friends.",
        "body": "Over three years, I collaborated closely with Santiago and his team, dedicating their talents full-time to PayGrade/Stash101. We achieved remarkable milestones, such as the successful acquisition by a prominent NYC fintech company. In the beginning they recovered our lost code (long story) and then helped scale us from 5,000 to around 250,000 users."
      },
      "danny_margolin": {
        "dialog": "From our experience with Software on the road, we can't find an area or complaint.",
        "body": "Software on the road’s work has been met with positive acclaim, thanks to their timely deliverables and efficient workflow. The team is highly receptive when it comes to feedback and concerns, and internal stakeholders are particularly impressed with the service provider's genuine care in resolving issues."
      },
      "clutch": "Read the full review on Clutch."
    },
    "devs": {
      "title": "Our dev team"
    },
    "contact": {
      "title": "Schedule a meeting",
      "description": "Will get you back as soon as possible.",
      "name": "Name",
      "email": "Email address",
      "phone": "Phone",
      "message": "Message",
      "success": "Thank you! Our team will be in touch shortly",
      "button": "Send"
    },
    footer: {
      "privacy-policy": "Privacy Policy",
      terms: "Terms",
      "meet": "Meet with us"
    },
    "partners": {
      "head-title": "Partners Program | Software on the Road",
      "head-description": "Do you have an agency and keep losing clients because you don't offer software development services? Work with us in our Partners program!",
      "hero-header": {
        prefix: "",
        highlight: "Partners",
        sufix: " in digital solutions"
      },
      "description": "Are you having trouble retaining clients because you don't offer software development? We propose a partnership model for Marketing and Advertising companies. We want to be your strategic ally to help our businesses grow.",
      "contact-us-cta": "Schedule a meeting",
      "program-title": "Partner Program",
      "program-description": "Our process is based on the strategic alliance of two agencies to offer a convenient solution: providing software development to your clients and thus prevent you from losing them...",
      "steps-title": "Partners in Three Steps",
      "steps-description": "Our partnership process is simple and aimed at solving your agency's problems.",
      "steps-1": "Request a call with us.",
      "steps-2": "Both parties share accurate information to develop a joint solution.",
      "steps-3": "Your agency joins the program, and we start developing projects together.",
      "book-meeting": {
        "title": "Book a call with us!",
        "description": "You can schedule a video meeting whith us here to tell us about your app idea. Choose the day and time that suits you best!",
      },
      "new-contact": {
        "title": "Let's chat!",
        "description": "Share your contact and we'll get the conversation started."
      },
      "whitelabel": "Whitelabel Mode",
      "whitelabel-you": "Your Agency",
      "whitelabel-you-description": "Lead both the commercial and operational coordination of the project. Decide the final price of the service for your clients (we will inform you of the cost beforehand).",
      "whitelabel-we": "Software on the Road",
      "whitelabel-we-description": "Form the technology team under your brand. Develop and execute the solution that meets your client's needs.",
      "whitelabel-cta": "Learn More",
      "associated": "Associated Mode",
      "associated-you": "Your Agency",
      "associated-you-description": "Identify the client's requirements, whether it's developing applications, customized software, or an E-commerce platform. Introduce us to the client and put us in touch with them.",
      "associated-we": "Software on the Road",
      "associated-we-description": "Lead the commercial, operational, and technical process to ensure the project is successful.",
      "associated-cta": "Learn More"
    },
    "augmented-reality-spatial-computing": {
      "hero": {
        "title": "Are you looking to drive more customers to your e-commerce business?",
        "description": "We know the development process necessary to lead a startup to success. We create your MVP designed and designed especially for you so that you can turn your idea into a functional software product, ready to acquire users and secure financing.",
        "callToAction": "Schedule free consultation call"
      },
      "demos": {
        "title": "Augmented reality applications that enhance your business",
        "body1": "Capture the attention of your customers with augmented reality in your E-Commerce products. We add it to your Shopify store, Woo-commerce, PrestaShop, Tiendanube and more",
        "body2": "Connect with your visitors interactively through virtual guides that improve the guided tour experience.",
        "body3": "Holographic solutions for companies. Create a strong impression the next time you hand out your business card.",
        "link": "View demo",
      }
    },
    "shopify": {
      "cal-meeting": "santypk4/free-consultation",
      "title": "Shopify Developers - Software On The Road ",
      "description": "",
      "meeting-link": 'https://cal.com/santypk4/free-consultation',
      "hero": {
        "title": "Get Your Time and Focus Back on What Matters",
        "description": "Tired of spending endless hours struggling with your e-commerce website? Let us handle the nitty-gritty so you can focus on growing your business. Hire our professionals for efficient and effective results.",
        "callToAction": "Book free consultation call"
      },
      "contact": {
        "title": "Unlock Your Business Potential on a Budget",
        "description": ""
      },
      "learn-video-more": {
        "title": "",
        "description": ""
      },
      "workflow": {
        "title": "Unlock Your Business Potential on a Budget",
        "description": "Experience top-notch service without breaking the bank. Our skilled designers, developers, and consultants offer their expertise to elevate your business without the commitment of a full-time employee."
      },
      "client-logos" : {
        "title": "Happy customers"
      },
      "book-meeting": {
        "title": "Looking for Shopify Plus Expertise?",
        "description": "Experience top-notch service without breaking the bank. Our skilled designers, developers, and consultants offer their expertise to elevate your business without the commitment of a full-time employee."
      }
    },
    "xr": {
      "cal-meeting": "santypk4/free-consultation",
      "title": "AR - Augmented Reality - VR - Virtual Reality - Spatial Computing - Software On The Road",
      "description": "",
      "meeting-link": 'https://cal.com/santypk4/free-consultation',
      "hero": {
        "title": "Get Your Time and Focus Back on What Matters",
        "description": "Tired of spending endless hours struggling with your e-commerce website? Let us handle the nitty-gritty so you can focus on growing your business. Hire our professionals for efficient and effective results.",
        "callToAction": "Book free consultation call"
      },
      "contact": {
        "title": "Unlock Your Business Potential on a Budget",
        "description": ""
      },
      "learn-video-more": {
        "title": "",
        "description": ""
      },
      "workflow": {
        "title": "Unlock Your Business Potential on a Budget",
        "description": "Experience top-notch service without breaking the bank. Our skilled designers, developers, and consultants offer their expertise to elevate your business without the commitment of a full-time employee."
      },
      "client-logos" : {
        "title": "Happy customers"
      },
      "book-meeting": {
        "title": "Are you ready to step into the future of shopping? ",
        "description": "magine a world where you can try before you buy, where every product feels like it's right in front of you. Welcome to the realm of Augmented Reality Shopping!"
      }
    },
  },
  es: {
    "blog": {
      "title1": "Bienvenido a nuestro",
      "title2": "blog",
      "description": "Ya sea que sea un profesional experimentado o recién esté comenzando su viaje en codificación, este blog está aquí para brindarle información valiosa, consejos prácticos e historias inspiradoras del mundo del desarrollo de software.",
      "subscribe-title": "Suscríbete a nuestro boletín y recibe una notificación cuando se cargue una nueva publicación en el blog!",
      "subscribe-label": "email",
      "subscribe-button": "Enviar",
      "title-cards": "Entradas",
    },
    "thank-you": {
      "title": "Gracias",
      "description": "Nos pondremos en contacto contigo muy pronto."
    },
    "thank-you-guide": {
      "title": "Gracias!",
      "description": "Revisa tu correo, allí hemos enviado la guia"
    },
    "shopify": {
      "cal-meeting": "santypk4/free-consultation",
      "title": "Custom software and app development - Software On The Road ",
      "description": "We build software and custom apps for your startup company. We create any kind of application, software or website you can imagine, with cutting edge technologies such as A.I., Blockchain solutions and native mobile app development.",
      "meeting-link": 'https://cal.com/santypk4/free-consultation',
      "hero": {
        "title": "Get Your Time and Focus Back on What Matters",
        "description": "Tired of spending endless hours struggling with your e-commerce website? Let us handle the nitty-gritty so you can focus on growing your business. Hire our professionals for efficient and effective results.",
        "callToAction": "Book free consultation call"
      },
      "contact": {
        "title": "Unlock Your Business Potential on a Budget",
        "description": "We will get in touch with you as soon as possible to provide you with a suitable quote and turn your product into reality."
      },
      "learn-video-more": {
        "title": "Conoce más sobre nuestro trabajo",
        "description": "Trabajamos codo a codo con nuestros clientes para realizar y entregar una aplicación funcional en el menor tiempo posible. En el siguiente vídeo te mostramos cómo es nuestro proceso y cómo trabajamos:"
      },
      "workflow": {
        "title": "Our work process",
        "description": "The process of creating an application is iterative, involving continuous improvement, taking into account feedback from the founder and the market."
      },
      "client-logos" : {
        "title": "Happy customers"
      },
      "book-meeting": {
        "title": "Looking for Shopify Plus Expertise?",
        "description": "Experience top-notch service without breaking the bank. Our skilled designers, developers, and consultants offer their expertise to elevate your business without the commitment of a full-time employee."
      }
    },
    cookie_consent: 'Este sitio web utiliza cookies para mejorar la experiencia del usuario. Al usar nuestro sitio o nuestros productos, estas de acuerdo con nuestra política de uso de cookies.',
    cookie_consent_accept: 'Acepto',
    index_page_title: "Software On The Road - Agencia de desarrollo",
    post_date: "Escrito el {postedOn, date, long}",
    not_found: {
      back_link: 'Volver a la pagina principal →',
    },
    "partners": {
      "head-title": "Programa para Partners | Software on the Road",
      "head-description": "¿Tienes una agencia y pierdes clientes por no ofrecer servicios de desarrollo de software? Trabaja junto a nosotros en la modalidad de Partners!",
      "hero-header": {
        prefix: "",
        highlight: "Rentabiliza",
        sufix: " tu cartera de clientes con desarrollo de software y apps"
      },
      "description": "Sabemos que las agencias son el primer punto de partida para clientes de software, pero muchas veces, las agencias los pierden al momento de que estos necesitan crear software personalizado, como apps o sitios web complejos... ",
      "contact-us-cta": "Saber mas",
      "program-title": "Programa de partners",
      "program-description": "Formamos una alianza con tu agencia, para que puedas maximizar tus ganancias, nos encargamos de armar un equipo de desarollo a la medida de tu cliente, tú gestionas la parte comercial y decides el precio final del servicio.",
      "steps-title": "Partners en tres pasos",
      "steps-description": "",
      "steps-1": "Reúnete con nosotros en una llamada.",
      "steps-2": "Completa y envía los documentos que te entregaremos luego de nuestra reunión.",
      "steps-3": "Tu agencia se adhiere al programa y comenzamos a desarrollar proyectos en conjunto.",
      "book-meeting": {
        "title": "Agenda una reunion hoy mismo",
        "description": "Nuestro proceso se basa en la alianza estratégica de dos agencias para poder ofrecer una solución conveniente: ofrecer desarrollo de software a tus clientes y, así, evitar que los pierdas...",
      },
      "whatsapp": {
        "placeholder": "Hola, quiero saber sobre ser Partners!",
        "chat-message": "Hola, soy Santiago Quinteros de Software on the Road, como puedo ayudarte?",
        "status-message": "Usualmente responde en 1 hora.",
      },
      "new-contact": {
        "title": "Hablemos",
        "description": 'Nos pondremos en contacto cuanto antes para darte un presupuesto acorde y volver tu producto realidad',
        "name": "Nombre*",
        "email": "Email*",
        "phone": "Teléfono",
        "message": "Mensaje*",
        "succes": "Gracias! Nuestro equipo se comunicará contigo a la brevedad",
        "button": "Enviar"
      },
      "meeting-link": "https://cal.com/santypk4/alianza",
      "cal-meeting": "santypk4/alianza",
      "whitelabel": "Modalidad 'Whitelabel'",
      "whitelabel-you": "Tu agencia",
      "whitelabel-you-description": "Encabezas la coordinación tanto comercial como operativa del proyecto. Decides el precio final del servicio para tu clientes (previamente te diremos cuánto te cuesta a ti).",
      "whitelabel-we": "Software on the Road",
      "whitelabel-we-description": "Formamos el equipo de tecnología bajo tu marca. Desarrollamos y ejecutamos la solución que satisface las necesidades de tu cliente.",
      "whitelabel-cta": "Conoce más",
      "associated": "Modalidad 'Asociado'",
      "associated-you": "Tu agencia",
      "associated-you-description": "Identifica las exigencias del cliente, ya sea para desarrollar aplicaciones, software personalizado o plataforma E-commerce. Nos introduce al cliente y nos pone en contacto con él.",
      "associated-we": "Software on the Road",
      "associated-we-description": "Llevamos adelante el proceso comercial, operativo y técnico para que el proyecto llegue a buen puerto",
      "associated-cta": "Conoce más",
    },
    "augmented-reality-spatial-computing": {
      "hero": {
        "title": "Estás buscando diferenciarte con Realidad Aumentada?",
        "description": "Diferénciate de tu competencia con una innovadora forma de presentar tus servicios. Incorpora la Realidad Aumentada ya sea en E-Commerce, tours virtuales o campañas publicitarias. La Realidad Aumentada (RA) transforma la experiencia de contacto con tus clientes permitiendo expandir el espacio físico del cliente con elementos virtuales.",
        "callToAction": "Agenda una reunion"
      },
      "hero2": {
        "title": "Qué beneficios me otorga la realidad aumentada?",
        "benefit1": "Aumentar las ventas: El 65% de los consumidores son más propensos a comprar después de experimentar un producto con RA.",
        "benefit2": "Mayor participación del cliente: La RA atrae a los clientes al involucrarlos activamente en la exploración de productos y experiencias.",
        "benefit3": "Diferenciación de la Competencia: Destaca entre tus competidores ofreciendo una experiencia de compra innovadora.",
        "benefit4": "Generación de Contenido Viral: Los clientes podrán compartir estas experiencias en las redes sociales, lo que aumenta la visibilidad de tu marca.",
        "benefit5": "Conexión Emocional con los Clientes: Crea una conexión más profunda entre tus clientes y tu marca.",
        "callToAction": "Agenda una reunion"
      },
      "demos": {
        "title": "Aplicaciones de la Realidad Aumentada a los distintos tipos de emprendimientos.",
        "subtitle": "Prueba demos haciendo click en los enlaces",
        "body1": "Capture the attention of your customers with augmented reality in your E-Commerce products. We add it to your Shopify store, Woo-commerce, PrestaShop, Tiendanube and more",
        "body2": "Connect with your visitors interactively through virtual guides that improve the guided tour experience.",
        "body3": "Holographic solutions for companies. Create a strong impression the next time you hand out your business card.",
        "link": "View demo",
      }
    },
    "xr": {
      "cal-meeting": "santypk4/asesoria-gratuita",
      "title": "AR - Augmented Reality - VR - Virtual Reality - Spatial Computing - Software On The Road",
      "description": "Desarrollamos aplicaciones y sistemas personalizados para que potencies tu negocio. Realizamos todo tipo de aplicaciones y sitios web, con tecnologias de vanguardia como Inteligencia Ariticial, soluciones basadas en Blockchain y codigo nativo.",
      "meeting-link": 'https://cal.com/santypk4/free-consultation',
      "hero": {
        "title": "Creamos la aplicación que necesitas!",
        "description": "Conocemos el proceso de desarrollo necesario para llevar una startup al éxito. Creamos tu MVP pensado y diseñado especialmente para ti para que puedas convertir tu idea en un producto de software funcional, listo para adquirir usuarios y asegurar financiamiento.",
        "callToAction": "Agenda tu asesoría gratuita"
      },
      "whatsapp": {
        "placeholder": "Me gustaría que me asesoren",
        "chat-message": "Hola, soy Santiago Quinteros de Software on the Road, como puedo ayudarte?",
        "status-message": "Usualmente responde en 1 hora.",
      },
      "workflow": {
        "title": "Conoce sobre nuestro proceso de trabajo",
        "description": "El proceso de creación de una aplicación es iterativo, involucra una constante mejora tomando en cuenta el feedback del founder y del mercado."
      },
      "book-meeting": {
        "title": "Agendá una reunion",
        "description": "Puedes agendar una reunión mediante videollamada con nosotros aquí para contarnos sobre tu idea de aplicación. Selecciona el día y horario que más te convenga!"
      },
      "learn-video-more": {
        "title": "Conoce más sobre nuestro trabajo",
        "description": "Trabajamos codo a codo con nuestros clientes para realizar y entregar una aplicación funcional en el menor tiempo posible. En el siguiente vídeo te mostramos cómo es nuestro proceso y cómo trabajamos:"
      },
      "client-logos" : {
        "title": "Empresas que confiaron en Software on the Road"
      },
      "contact": {
        "title": "Contáctate con nosotros y cuéntanos tu idea",
        "description-hero": 'WhatsApp: <a href="https://wa.me/5493413828249" target="_blank"> +5493413828249 </a> o completa el formulario.',
        "description": 'Nos pondremos en contacto cuanto antes para darte un presupuesto acorde y volver tu producto realidad',
        "name": "Nombre*",
        "email": "Email*",
        "phone": "Teléfono",
        "message": "Mensaje*",
        "succes": "Gracias! Nuestro equipo se comunicará contigo a la brevedad",
        "button": "Enviar"
      }
    },
    "landing-app-dev": {
      "cal-meeting": "santypk4/asesoria-gratuita",
      "title": "Desarrollo de aplicaciones y sistemas personalizados - Software On The Road",
      "description": "Desarrollamos aplicaciones y sistemas personalizados para que potencies tu negocio. Realizamos todo tipo de aplicaciones y sitios web, con tecnologias de vanguardia como Inteligencia Ariticial, soluciones basadas en Blockchain y codigo nativo.",
      "meeting-link": 'https://cal.com/santypk4/free-consultation',
      "hero": {
        "title": {
          "prefix": "Creamos ",
          "highlight": "la aplicación",
          "sufix": " que necesitas!"
        },
        "description": "Conocemos el proceso de desarrollo necesario para llevar una startup al éxito. Creamos tu MVP pensado y diseñado especialmente para ti para que puedas convertir tu idea en un producto de software funcional, listo para adquirir usuarios y asegurar financiamiento.",
        "callToAction": "Agenda tu asesoría gratuita"
      },
      "whatsapp": {
        "placeholder": "Me gustaría que me asesoren",
        "chat-message": "Hola, soy Santiago Quinteros de Software on the Road, como puedo ayudarte?",
        "status-message": "Usualmente responde en 1 hora.",
      },
      "workflow": {
        "title": "Conoce sobre nuestro proceso de trabajo",
        "description": "El proceso de creación de una aplicación es iterativo, involucra una constante mejora tomando en cuenta el feedback del founder y del mercado."
      },
      "book-meeting": {
        "title": "Agendá una reunion",
        "description": "Puedes agendar una reunión mediante videollamada con nosotros aquí para contarnos sobre tu idea de aplicación. Selecciona el día y horario que más te convenga!"
      },
      "learn-video-more": {
        "title": "Conoce más sobre nuestro trabajo",
        "description": "Trabajamos codo a codo con nuestros clientes para realizar y entregar una aplicación funcional en el menor tiempo posible. En el siguiente vídeo te mostramos cómo es nuestro proceso y cómo trabajamos:"
      },
      "client-logos" : {
        "title": "Empresas que confiaron en Software on the Road"
      },
      "contact": {
        "title": "Contáctate con nosotros y cuéntanos tu idea",
        "description-hero": 'WhatsApp: <a href="https://wa.me/5493413828249" target="_blank"> +5493413828249 </a> o completa el formulario.',
        "description": 'Nos pondremos en contacto cuanto antes para darte un presupuesto acorde y volver tu producto realidad',
        "name": "Nombre*",
        "email": "Email*",
        "phone": "Teléfono",
        "message": "Mensaje*",
        "succes": "Gracias! Nuestro equipo se comunicará contigo a la brevedad",
        "button": "Enviar"
      },
      "new-contact": {
        "title": "Hablemos!",
        "description-hero": 'WhatsApp: <a href="https://wa.me/5493413828249" target="_blank"> +5493413828249 </a> o completa el formulario.',
        "description": 'Nos pondremos en contacto cuanto antes para darte un presupuesto acorde y volver tu producto realidad',
        "name": "Nombre*",
        "email": "Email*",
        "phone": "Teléfono",
        "message": "Mensaje*",
        "succes": "Gracias! Nuestro equipo se comunicará contigo a la brevedad",
        "button": "Enviar"
      }
    },
    "navbar": {
      "services": "Servicios",
      "projects": "Proyectos",
      "clients": "Reviews",
      "blog": "Blog",
      "contact": "Contáctanos"
    },
    "hero": {
      "title": {
        "prefix" :"Software con ",
        "highlight": "tecnología de vanguardia.",
        "sufix": ""
      },
      "description": "Somos tu Partner de desarrollo! Nuestro objetivo es guiarte en tu trayectoria emprendedora, ya sea que necesites ayuda para desarrollar tu Producto Mínimo Viable (MVP) o escalar el desarrollo de tu software para satisfacer la creciente demanda de usuarios o asegurar financiamiento.",
      "button": "Contáctanos"
    },
    "services": {
      "title": "Servicios",
      "for-startups": {
        "title": "Para Startups",
        "description": "Conocemos el proceso de desarrollo necesario para llevar a una Startup al éxito. Creamos tu MVP pensado y diseñado para que puedas convertir rápidamente tu idea en un producto de software funcional, listo para adquirir usuarios y asegurar financiamiento.",
        "firstItem": {
          "title": "MVP para Startups",
          "description": "Te ayudamos a simplificar el desarrollo de productos, dando énfasis en la característica principal que diferencia a tu producto de la competencia, y que causa disrupción en el mercado, de manera que tu producto llegue al mercado lo más rápido posible, ahorrando tiempo y dinero."
        },
        "secondItem": {
          "title": "Desarrollo de Aplicaciones SaaS",
          "description": "Construimos Software as a Service, productos que escalan con cash-flow positivo. Creamos tu SaaS para brindar valor desde el primer día."
        },
        "thirdItem": {
          "title": "Un CTO a tu disposición",
          "description": "Sabemos lo difícil que es encontrar un director de tecnología, puedes confiar en nosotros para la dirección de tecnológica de tú startups, te ayudamos a tomar decisiones de stack tecnológico, mientras encuentras tu propio CTO."
        }
      },
      "staff-augmentation": {
        "title": "Outsourcing y aumento de personal IT",
        "description": "Tenemos los desarrolladores que necesitas! \r\r\r",
        "development-outsourcing": "Desarrollo Outsourcing: Armamos un equipo de desarrollo, ux/ui, y project management, a la medida de tu proyecto, nosotros nos encargamos de toda la gestión del desarrollo, y tú de la gestión comercial de tu cliente. ",
        "it-augmentation": "IT Staffing Augmentation: Nuestros expertos se sumergirán en la cultura de tu empresa y serán parte de tu equipo, para que puedas acelerar el desarrollo de tu proyecto, sin comprometer la calidad.",
        "firstItem": {
          "title": "Incremento de personal I.T.",
          "description": "Tenemos una selección de perfiles I.T. entrenados y capacitados en el desarrollo de software ágil, nosotros nos encargamos de las cargas sociales, proveer hardware y recambio de personal."
        },
        "secondItem": {
          "title": "Desarrollo Outsourcing",
          "description": "Armar un equipo de desarrollo experimentado en tecnologías de vanguardia puede llevar meses de reclutamiento. Nosotros te armamos un equipo de desarrollo a la medida de tu necesidad tecnológica"
        },
        "thirdItem": {
          "title": "Consultoría",
          "description": "Entendemos que muchas veces no es necesario un equipo, o un personal de I.T si ya lo tienes, pero quieres entrenarlos en las últimas tendencias como AWS, o resolver problemas actuales de escalabilidad en Node.js, nuestros expertos Santiago Quinteros y Guido Rial pueden capacitar a tus desarrolladores con consultorías diseñadas a tu medida."
        }
      }
    },
    "portfolio": {
      "title": "Nuestros proyectos",
      "visit": "Ver proyecto",
      "resuite": {
        "description": "Plataforma de investigación del mercado inmobiliario, fácil de usar, que ofrece toda la información que necesita para realizar un análisis en profundidad."
      },
      "capablemaker": {
        "description": "Plataforma de aprendizaje on-line para profesores de oficio y a sus estudiantes que facilita la instrucción óptima y oportuna, bajo un modelo de enseñanza asincrónico. Asiste a los educadores de oficios en el desarrollo y distribución de contenido.      "
      },
      "paygrade": {
        "description": "Plataforma de educación financiera diseñada para niños y adolescentes, con el objetivo de impartir conocimientos financieros del mundo real."
      },
      "snapsales": {
        "description": "Plataforma para crear y vender productos por criptomonedas, crea landing pages instantaneas de manera gratuita y recibe pagos con la seguridad de la blockchain."
      },
      "drinkko": {
        "description": "Drinkko es un punto de encuentro entre personas que quieren beber buena cerveza a precios accesibles y bares que necesitan atraer clientes. A través de Drinkko, los bares pueden atraer clientes ofreciendo cervezas con descuento en momentos específicos del día. Al mismo tiempo, los usuarios reciben notificaciones sobre los precios rebajados de la cerveza en bares cercanos."
      },
      "cryptocampo": {
        "description": "Proyecto de tokenización de activos del mundo real, utiliza la tecnología blockchain para crear un modelo de crowdfunding que permite a la sociedad invertir en proyectos colectivos."
      }
    },
    "clients": {
      "title": "Testimonios de clientes",
      "capablemaker": {
        "dialog": "Software on the road fue una de las mejores empresas con las que trabajé.",
        "body": "El trabajo de Software on the Road condujo a un exitoso proceso de incorporación de nuevos usuarios. El equipo gestionó sus tareas de manera efectiva, trabajó eficientemente y se esforzó al máximo para asegurar el éxito del proyecto. Quedamos impresionados por su habilidad para seguir instrucciones de manera precisa y entregar exactamente lo que el cliente necesitaba."
      },
      "ktblack": {
        "dialog": "Su profesionalismo y comunicación son impresionantes.",
        "body": "Software on the Road ha demostrado un progreso exitoso en el proyecto utilizando la herramienta interna de gestión de proyectos del cliente, lo que demuestra adaptabilidad. El equipo ha entregado los elementos a tiempo y ha sido muy receptivo a las necesidades del cliente. Además, su comunicación y profesionalismo son excelentes."
      },
      "paygrade": {
        "dialog": "Software on the Road no fue solo un equipo de desarrollo extraordinario; se convirtieron en amigos de confianza.",
        "body": "Durante más de tres años, colaboré estrechamente con Santiago y su equipo, dedicando sus talentos a tiempo completo a PayGrade/Stash101. Logramos hitos notables, como la exitosa adquisición por parte de una destacada empresa fintech de Nueva York. Al principio recuperaron nuestro código perdido (larga historia) y luego nos ayudaron a escalar de 5,000 a alrededor de 250,000 usuarios."
      },
      "danny_margolin": {
        "dialog": "Tuvimos una gran experiencia con Software on the Road, sin ningún área problemática ni quejas.",
        "body": "El trabajo de Software on the Road ha sido recibido con aclamaciones positivas, gracias a sus entregables a tiempo y un flujo de trabajo eficiente. El equipo es altamente receptivo cuando se trata de retroalimentación y preocupaciones, y los stakeholders internos están particularmente impresionados con el cuidado genuino del proveedor de servicios al resolver problemas."
      },
      "clutch": "Lee la reseña completa en Clutch."
    },
    "devs": {
      "title": "Nuestro equipo"
    },
    "contact": {
      "title": "Hablemos",
      "description": "Nos pondremos en contacto cuanto antes",
      "name": "Nombre*",
      "email": "Email*",
      "phone": "Teléfono*",
      "message": "Mensaje*",
      "succes": "Gracias! Nuestro equipo se comunicará a la brevedad",
      "button": "Enviar"
    },
    "lead-magnet": {
      "mvp": {
        "title1": "Convierte tu idea",
        "title2": "en un MVP",
        "title3": "hoy mismo!",
        "description": "¿Tu idea ya está lista para despegar y crees que puede ser viable? A continuación te invitamos a registrarte para obtener la guía completa de 100 páginas en tu mail. Cuando la finalices tendrás todo lo necesario para tener tu MVP organizado.",
      },
      "learn": {
        "title": "Que aprenderás con esta guía",
        "list1": "Aprender a evaluar si tu idea de app es viable.",
        "list2": "Descubrir cómo crear una startup y levantar capital.",
        "list3": "Desarrollar tu MVP de forma eficiente y segura.",
        "list4": "Lanzar tu app al mercado y hacerla crecer.",
      },
      "steps": {
        "title": "Cómo funciona",
        "description": "La organización del contenido te permitirá hacer crecer tu idea semana a semana hasta tener un proyecto armado. Al final del contenido te podremos asesorar para que puedas armar tu MVP con nosotros.",
        "step1": "Regístrate para recibir la guía en tu mail.",
        "step2": "Te inviaremos los capítulos semanalmente para que puedas poner tus nuevos conocimientos en práctica.",
        "step3": "Cuando finalicen las entregas te invitaremos a que nos cuentes sobre ella y su viabilidad para que podamos llevar a cabo tu app.",
      },
      "download": {
        "button": "DESCARGA AQUÍ!"
      }
    },
    "careers-mkt": {
      "work-with-us": {
        "title": "Te estamos buscando!",
        "description-1": "Unete a un equipo creativo e innovador, comprometido con el éxito de las Start Ups.",
        "description-2": "Desarrollamos productos de software para Startups con tecnologías modernas y mucha creatividad, empresas tecnológicas demandates, trabajar aquí es realmente un desafío."
      },
      "about-us": {
        "title": "Somos Software on the Road",
        "description": "Una agencia de desarrollo especializada en crear soluciones tecnológicas innovadoras, para Start ups de todo America y Europa."
      },
      "selection-process": {
        "title": "Proceso de selección",
        "step-1": "Dejanos tus datos y CV en el formulario (al final de la pagina)",
        "step-2": "Analizaremos tu perfil, y te invitaremos a una entrevista inicial para conocerte.",
        "step-3": "Nos reunimos virtualmente, nos interesa mucho conocer tu perfil y escuchar sobre tu experiencia.",
      },
      "requirements": {
        "title": "Requisitos",
        "list-1": "Requerido: Experiencia comprobable en marketing digital.",
        "list-2": "Experiencia en marketing para servicios digitales",
        "list-3": "Social media management, interaccion y creación de contenido: Meta, LinkedIn, YouTube",
        "list-4": "Copywriting, redaccion, SEO",
        "list-5": "Email Marketing",
        "list-6": "Anuncios en Meta",
        "list-7": "NO BUSCAMOS COMMUNITY MANAGERS",
      },
      "benefits": {
        "title": "Beneficios",
        "list-1": "Sueldo en USDT.",
        "list-2": "Trabajo 100% remoto desde Argentina.",
        "list-3": "Horario flexible y autonomia.",
        "list-4": "Cobertura de inglés, Internet y medicina prepaga.",
        "list-5": "Bonos de fin y mitad de año.",
        "list-6": "10 días de vacaciones durante el primer año, aumenta con la antigüedad.",
      },
      "apply-here-button": "APLICA AQUÍ!"
    },
    "careers-marketing": {
      "work-with-us": {
        "title": "Te estamos buscando!",
        "description-1": "Unete a un equipo creativo e innovador, comprometido con el éxito de las Start Ups.",
        "description-2": "Desarrollamos productos de software para Startups con tecnologías modernas y mucha creatividad, empresas tecnológicas demandates, trabajar aquí es realmente un desafío."
      },
      "about-us": {
        "title": "Somos Software on the Road",
        "description": "Una agencia de desarrollo especializada en crear soluciones tecnológicas innovadoras, para Start ups de todo America y Europa."
      },
      "selection-process": {
        "title": "Proceso de selección",
        "step-1": "Dejanos tus datos y CV en el formulario (al final de la pagina)",
        "step-2": "Analizaremos tu perfil, y te invitaremos a una entrevista inicial para conocerte.",
        "step-3": "Nos reunimos virtualmente, nos interesa mucho conocer tu perfil y escuchar sobre tu experiencia.",
      },
      "requirements": {
        "title": "Requisitos",
        "list-1": "Requerido: Experiencia comprobable en marketing digital.",
        "list-2": "Experiencia en marketing para servicios digitales",
        "list-3": "Social media management, interaccion y creación de contenido: Meta, LinkedIn, YouTube",
        "list-4": "Copywriting, redaccion, SEO",
        "list-5": "Email Marketing",
        "list-6": "Anuncios en Meta",
        "list-7": "NO BUSCAMOS COMMUNITY MANAGERS",
      },
      "benefits": {
        "title": "Beneficios",
        "list-1": "Sueldo en USDT.",
        "list-2": "Trabajo 100% remoto desde Argentina.",
        "list-3": "Horario flexible y autonomia.",
        "list-4": "Cobertura de inglés, Internet y medicina prepaga.",
        "list-5": "Bonos de fin y mitad de año.",
        "list-6": "10 días de vacaciones durante el primer año, aumenta con la antigüedad.",
      },
      "apply-here-button": "APLICA AQUÍ!"
    },
    "careers": {
      "work-with-us": {
        "title": "Te estamos buscando!",
        "description-1": "Unete a un equipo compromedito con la Ingeniera de Software.",
        "description-2": "Desarrollamos productos altamente escalables para empresas tecnológicas demandates, trabajar aquí es realmente un desafío."
      },
      "about-us": {
        "title": "Somos Software on the Road",
        "description": "Una agencia especializada en crear soluciones tecnológicas innovadoras, siempre trabajando con tecnologías de punta. Desde hace mas de 6 años, ofrecemos nuestro ingenio y sabiduría a las empresas de vanguardia de America y Europa."
      },
      "selection-process": {
        "title": "Proceso de selección",
        "step-1": "Dejanos tus datos y CV en el formulario (al final de la pagina)",
        "step-2": "Analizaremos tu perfil, y te invitaremos a una entrevista inicial para conocerte.",
        "step-3": "Breve ejercicio de código en vivo y una entrevista técnica para que puedas demostrar tu conocimiento.",
      },
      "requirements": {
        "title": "Requisitos",
        "list-1": "Experiencia sólida en Node.js o React.js, incluyendo la realización de proyectos reales.",
        "list-2": "Conocimiento de patrones de diseño y conceptos de ingeniería de software (SOLID, KISS, DRY, etc).",
        "list-3": "Estructura de datos, algoritmos, tiempos de complejidad.",
        "list-4": "Competencias en Javascript/Typescript, incluyendo habilidades con arrays, maps, funciones de arrays, promesas y código asíncrono.",
        "list-5": "Nivel de inglés mínimo B2, con la capacidad de alcanzar un nivel conversacional de C1 ya que trabajamos directamente con clientes de habla inglesa y mantenemos reuniones semanales con ellos. La comunicación oral es esencial.",
      },
      "benefits": {
        "title": "Beneficios",
        "list-1": "Sueldo en USDT.",
        "list-2": "Trabajo 100% remoto desde Argentina.",
        "list-3": "Horario flexible y autonomia.",
        "list-4": "Oportunidades de crecimiento, plan de carrera y proyectos impulsaran tu carrera.",
        "list-5": "Cobertura de inglés, Internet y medicina prepaga.",
        "list-6": "Bonos de fin y mitad de año.",
        "list-7": "10 días de vacaciones durante el primer año, aumenta con la antigüedad.",
      },
      "apply-here-button": "APLICA AQUÍ!"
    },
    "app-success-guide": {
      "description": "¿Tu idea ya está lista para despegar y crees que puede ser viable? A continuación te invitamos a registrarte para obtener la guía completa de 100 páginas en tu mail. Cuando la finalices tendrás todo lo necesario para tener tu MVP organizado.",
      "list-title": "Capítulos de la guía",
      "list-item-1": "Introducción al Ecosistema de Startups.",
      "list-item-2": "Como realizar estudios de mercado efectivos.",
      "list-item-3": "Como definir tu software de manera correcta con análisis funcional.",
      "list-item-4": "Como prototipar con Figma.",
      "list-item-5": "Como crear una Landing Page que convierte clientes!",
      "list-item-6": "Desarrollo de un MVP funcional con herramienta low-code.",
      "list-item-7": "Como realizar un Launch de tu producto en product hunt.",
      "form-title": "Dejanos tu mail y te enviaremos la guía completamente gratis."
    },
    footer: {
      "privacy-policy": "Políticas de privacidad",
      terms: "TyC",
      "meet": "Agendá una reunión"
    }
  },
};
